<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model
            :colon="false"
            :model="queryForm"
            layout="inline"
            @keyup.enter.native="query"
          >
            <a-form-model-item>
              <a-input v-model="queryForm.name" placeholder="考试名称" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-cascader
                :changeOnSelect="true"
                placeholder="归属目录"
                v-model="queryForm.type"
                :options="useableTree"
                :fieldNames="{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }"
              />
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-button
              v-if="$getPermission($route.path + '/add')"
              @click="$router.push($route.path + '/add')"
              type="primary"
            >创建考试</a-button>
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :dataSource="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        rowKey="id"
      >
        <a-table-column title="考卷名" data-index="name"></a-table-column>

        <a-table-column title="考试时长(分钟)" data-index="duration" align="center" width="140px"></a-table-column>

        <a-table-column title="归属目录" align="center">
          <template slot-scope="text">{{ getType(text) }}</template>
        </a-table-column>

        <a-table-column title="开始日期" data-index="startTime" align="center"></a-table-column>
        <a-table-column title="结束日期" data-index="endTime" align="center"></a-table-column>

        <a-table-column title="创建人" data-index="createBy" align="center"></a-table-column>
        <a-table-column title="创建日期" data-index="createAt" align="center"></a-table-column>

        <a-table-column title="是否答题" align="center" width="200px">
          <template slot-scope="text">
            <span class="center">
              <DataDictFinder
                v-if="text.examUserStatus !== 'answered'"
                dictType="exam_answer_status"
                :dictValue="text.examUserStatus"
              />
              <template v-else>
                <span style="color: #f00" v-if="!text.examUserEndTime">
                  <!-- 用户已答题，但没交卷（离开页面），去判断是否超时，如果返回true，说明还没到时间，还可以交卷 -->
                  {{ calculateTime(text) ? "未交卷" : "超时未交卷" }}
                </span>
                <span v-else style="color: green">已交卷</span>
              </template>
            </span>
          </template>
        </a-table-column>

        <a-table-column align="center" title="操作" width="140px">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                v-if="$getPermission($route.path + '/edit')"
                @click.prevent="
                  $router.push($route.path + '/edit?id=' + text.id)
                "
              >编辑</a>

              <a
                href="#"
                class="danger"
                v-if="$getPermission($route.path + '/delete')"
                @click.prevent="deleteText(text)"
              >删除</a>
              <template v-if="$getPermission($route.path + '/start')">
                <template v-if="text.examUserStatus === 'answered'">
                  <template v-if="calculateTime(text) && !text.examUserEndTime">
                    <a href="#" @click.prevent="continueExam(text)">继续考试</a>
                  </template>
                  <template v-else>
                    <a href="#" @click.prevent="seeExamResult(text)">考试结果</a>
                  </template>
                </template>
                <template v-else>
                  <a href="#" @click.prevent="startExam(text)">开始考试</a>
                </template>
              </template>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchList, remove } from "@/api/exam";

import study from "@/mixins/study";

export default {
  mixins: [watermark, study],

  data() {
    return {
      queryForm: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, queryForm } = this;

      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize,
        ...queryForm,
        type:
          Array.isArray(queryForm.type) && queryForm.type.length > 0
            ? queryForm.type[queryForm.type.length - 1]
            : undefined
      })
        .then(res => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.queryForm = {};
      this.current = 1;
      this.getList();
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({
            id: text.id
          }).then(() => {
            that.getList();
          });
        }
      });
    },

    getType(text) {
      let types = [];
      this.findFatherNames(types, text.type);
      return types.join("/");
    },
    startExam(text) {
      const that = this;
      this.$confirm({
        title: `本次考试时间为${text.duration}分钟，请按时交卷！`,
        onOk() {
          that.$router.push(`${that.$route.path}/start?id=${text.id}`);
        }
      });
    },
    continueExam(text) {
      this.$router.push(`${this.$route.path}/start?id=${text.id}`);
    },
    seeExamResult(text) {
      this.$router.push(`${this.$route.path}/start?id=${text.id}`);
    },

    calculateTime(text) {
      // 如果用户已经有了开始考试时间，说明之前开始了考试，这时候需要判断
      // 当前时间减去开始时间，看有多少分钟，如果没超过考试时长，说明还有时间考试，
      // 如果超过了考试时长，那就说明用户已经没办法考试了
      const startTemp = new Date(text.examUserStartTime).valueOf();
      const nowTemp = new Date().valueOf();
      const minuteDiff = Math.round((nowTemp - startTemp) / 1000 / 60);
      const surplus = text.duration - minuteDiff;
      return surplus >= 2;
    }
  }
};
</script>